<template>
  <div class="chat-box" v-bind:class="{visible: isChatVisible}">
    
    <div class="side-panel" :class="[this.menuOpen === true ? 'open' : '']">
      <div class="inner-box">
        <MenuPopup :settings="settings" :chatSettings="chatSettings"  :baseServerAddress="baseServerAddress"
        @close-chat="$emit('close-chat')" 
        @open-menu="openMenu"
        @view-history="$emit('view-history')"
        @delete-history="$emit('delete-history')"
        ></MenuPopup>
      </div>
    </div>
    <HeaderBox :settings="settings" :chatSettings="chatSettings" :menuOpen="menuOpen" v-on:close-chat="$emit('close-chat')" v-on:open-menu="openMenu"/>
    <MessageBox ref="messageBox" :settings="settings" :chatSettings="chatSettings" :newTextQuery="newTextQuery" :isChatVisible="isChatVisible" :menuOpen="menuOpen" @user-query-created="userQueryCreated" @run-action="runAction"/>
    <TextInput @submit-query="userSubmitQuery" :settings="settings" v-on:change-language="changeLanguage"/>
  </div>
</template>

<script>
import HeaderBox from "./HeaderBox";
import MessageBox from "./MessageBox";
import TextInput from "./TextInput";
import ApiClient from "../helpers/ApiClient";
import MenuPopup from "./MenuPopup";

export default {
  name: "ChatBox",
  components: {
    HeaderBox,
    MessageBox,
    TextInput,
    MenuPopup
  },
  mixins: [
    ApiClient
  ],
  props:{
      isChatVisible: Boolean,
      settings: Object,
      chatSettings: Object,
      welcomeMessages: Array,
      welcomeMessageContexts: Array,
      developmentMod: Boolean
  },
  data(){
    return{
      newTextQuery: null,
      menuOpen: false
    }
  },
  methods: {
    //Fired when MessageBox acknowledges new user query and has copied the query value
    userQueryCreated(){
      this.newTextQuery = null;
    },
    userSubmitQuery(query){

      this.newTextQuery = query.trim();
    },
    changeLanguage(lang){
      this.$emit('change-language', lang);
    },
    openMenu(){
      this.menuOpen = !this.menuOpen;
    },
    runAction(action){
      this.$emit('run-action',action);
    }
  },
  mounted() {
    //Get the session ID for this browser, OR make new one.
      if(this.welcomeMessages.length == 0) return;
      this.$refs.messageBox.createNewMessages(this.welcomeMessages,this.welcomeMessageContexts,this.settings.suggestionBehaviour);
      this.$emit("clean-welcome-messages");
    //Let's fetch session cookie, if one is available
  },
  watch : {
    welcomeMessages: function(){
      if(this.welcomeMessages.length == 0) return;
      this.$refs.messageBox.createNewMessages(this.welcomeMessages,this.welcomeMessageContexts,this.settings.suggestionBehaviour);
    }
  }
};
</script>

<style scoped>
/* TESTING */

.inner-box {
  display: flex;
  flex-direction: column;
  width: 288pt;
  height: 480pt;
  max-height: 75vh;
  padding: 0px;
  position: absolute;
  z-index: 2147483647;
  overflow: hidden;
  font-size: 16px;
  
  /* transition: width 1s ease-out, height 1s ease-out, opacity 0.5s ease-in-out, transform 0.5s; */
}

/* Styles for smaller screens like SmartPhones */
@media only screen and (max-width: 333pt), only screen and (orientation: landscape) and (max-height: 400pt){
  .inner-box {
    display: flex;
    width: 100%;
    height: 100%;
    max-height: 100%;
    border-radius: 0;
  }
}

.side-panel{
  position: absolute;
  overflow: hidden;
  z-index: 10000;
  height: 100%;
  width: 100%;
  background:#ffffff;
  transition: width 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out;
  transform-origin: left;
  transform: scaleX(0);
}

.side-panel.open{
  transform: scaleX(1);
}


.chat-box {
  display: flex;
  flex-direction: column;
  width: 288pt;
  height: 480pt;
  transition: height 0.5s;
  max-height: 75vh;
  padding: 0px;

  bottom: 36pt;
  right: 36pt;
  top: auto;
  position: fixed;

  /*border: 1px solid black;*/
  background: transparent;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 25px;
  border-radius: 9pt;
  border-top-left-radius: 0;

  z-index: 2147483647;
  overflow: hidden;

  font-size: 16px;

  opacity: 0;
  transform: scale(0);
  transform-origin: bottom right;
  transition:opacity 0.15s ease-in, transform 0.3s ease-in;
  
  /* transition: width 1s ease-out, height 1s ease-out, opacity 0.5s ease-in-out, transform 0.5s; */
}

.chat-box.visible{
  transition: transform 0.3s ease-out, opacity 0.15s ease-in 0.15s;
  transform: scale(1);
  opacity: 1;
}


/* Styles for smaller screens like SmartPhones */
@media only screen and (max-width: 333pt), only screen and (orientation: landscape) and (max-height: 400pt){
  .chat-box {
    /* border: none; */
    border-radius: 0;
    bottom: 0;
    right: 0;
    display:none;

    transition: none;
  }
  .chat-box.visible{
    display: flex;
    width: 100%;
    height: 100%;
    max-height: 100%;
  }
}

/* Should trigger when people open their keyboards */
@media only screen and (orientation: landscape) and (max-height: 120pt){
  .message-box{
    display: none;
  }

  .text-input {
    height: 100%;
  }

  .text-input textarea{
    height: 90%;
    font-size: 2;
  }
}



</style>