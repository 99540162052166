<template>
    <div class="email-container">
        <template>
            <p><label>{{i18n.$t('email')}}</label></p>
            <input class="email-form" type="text" v-model="parameters.user_email" :disabled="sent"/>
            <p><label>{{i18n.$t('body')}}</label></p>
            <textarea class="email-form" v-model="parameters.body_content" :disabled="sent"/>
            <p v-if="error != ''" class="validation-errors">{{i18n.$t(error)}}</p>
            <button @click="sendMail" :disabled="sent" :class="[sent ? 'sent':'']">{{i18n.$t('send')}}</button>
        </template>
    </div>
</template>

<script>
import {useI18n} from '../plugins/i18n';
export default {
    name: 'EmailMessage',
    setup() {
        const i18n = useI18n();
        return {i18n};
    },
    props: {
        parameters: Object
    },
    data(){
        return{
            sent: false,
            error: ''
        }
    },
    methods: {
        sendMail(){
            const pattern = new RegExp("^[a-z0-9!#$%&'*+/=?^_‘{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_‘{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$");
            if(this.parameters.user_email == "" || !pattern.test(this.parameters.user_email)){
                this.error = 'invalid_email';
                return;
            }
            if(this.parameters.body_content == ""){
                this.error = 'invalid_body';
                return;
            }
            this.error = '';
            this.$emit("send");
            this.sent = true;
        }
    },
    created(){
        //Let's add all params if not already included
        if(!("user_email" in this.parameters)) this.parameters.user_email = "";
        if(!("subject_content" in this.parameters)) this.parameters.subject_content = "";
        if(!("body_content" in this.parameters)) this.parameters.body_content = "";
        if(!("hidden_content" in this.parameters)) this.parameters.hidden_content = "";
        if(!("success_message" in this.parameters)) this.parameters.success_message = "";
    }
}
</script>

<style lang="scss">
.email-container {
    width: 80%;
    font-size: 14px;
    clear: both;

    padding: 0.5em;
    margin-bottom:0.25em;
    margin-left: 10px;

    border-radius: 10px;
    border: 1px solid $suggestion-background;
}

.email-container input {
    padding:0.5em;

    margin-top: 10px;
    margin-bottom: 10px;
}

.email-container p{

    padding: 0;
    margin: 0;
}

.email-container label{
    font-weight: 600;
}

.validation-errors{
    width: 100%;
    height: 1em;
}

.email-container button{
    $suggestion-text-color: #FFF;
    border-radius: 10px;
    border: 0;

    background: $suggestion-background;
    padding: 0.5em 1em;
    margin-top: 0.5em;
    width: 100%;
}

.email-container button.sent{
    background: rgb(202, 202, 202);
}
</style>