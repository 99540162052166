<template>
  <div v-if="!hidden && ((successfulHandshake && !this.chatSettings.underMaintenance) || settings.assistantId == 'testing')" id="evenstad-chat">
    <ChatBox v-bind:isChatVisible="isChatVisible" 
    ref="chatbox"
      :settings="settings"
      :chatSettings="chatSettings"
      :welcomeMessages="welcomeMessages"
      :welcomeMessageContexts="welcomeMessageContexts"
      :developmentMod="developmentMod"
      @change-language="changeLanguage"
      @clean-welcome-messages="cleanWelcomeMessages"
      @close-chat="closeChat"
      @view-history="viewChatHistory"
      @delete-history="deleteChatHistory"
      @run-action="runAction"
      />
      
    <a v-on:click.stop="toggleChat">
      <!-- Evenstad Bot  -->
      <!-- <ChatToggle backgroundColor="#474b62" v-bind:class="{enabled: !isChatVisible}"/> -->
     
      <ChatToggle :settings="settings" :class="{enabled: !isChatVisible}"/>

      <WelcomeMessage :settings="settings" :chatSettings="chatSettings" :message="popUpMessage"  :class="[hasBeenOpened ? 'hidden' : '']"/>

    </a>
  </div>
</template>

<script>
import { provideI18n, useI18n } from './plugins/i18n';
import { startIntegrations, useIntegrations} from './integrations/integrations';

import ChatBox from './components/ChatBox';
import ChatToggle from './components/ChatToggle';
import WelcomeMessage from './components/WelcomeMessage';
import ApiClient from './helpers/ApiClient';
import { v4 as uuidv4 } from 'uuid';


export default {
  name: 'EvenstadChat',
  components:{
    ChatBox,
    ChatToggle,
    WelcomeMessage
  },
  setup() {
    provideI18n({
      language: "",
      defaultLang: "en"
    });
    const i18n = useI18n();

    startIntegrations({});

    const integrations = useIntegrations();

    //INTEGRATIONS INIT
    integrations.run(null,'zendesk','hide');


    return {i18n, integrations};
  },
  mixins: [
    ApiClient
  ],
  data(){
    return {
      hidden: false,
      isChatVisible: false,
      hasBeenOpened: false,
      settings: {
        assistantId: process.env.VUE_APP_PROJECT_ID,
        sessionId: null,
        defaultLang: "",
        language: "",
        allowedLangs: [],
        suggestionBehaviour: ""
      },
      chatSettings: {
        displayName: "",
        logoUrl: "",
        delayPerMessageMs: 0,
        underMaintenance: false,
        maintenanceNotification: "",
        welcomePopupEnabled: false,
        welcomePopupAfterSeconds: 0

      },
      userHasSelectedLang: false,
      welcomeIntentId: "",
      welcomeMessages:[],
      welcomeMessageContexts: [],
      popUpMessage: {},
      successfulHandshake: false,
      developmentMod: false
    }
  },
  methods: {
    viewChatHistory(){
      alert("Showing chat history!");
    },
    deleteChatHistory(){
      const successCB = () => {
        this.$root.$cookies.remove("evenstad-chat-open");
        window.sessionStorage.removeItem("evenstad-chat-session-id");
        window.sessionStorage.removeItem("evenstad-chat-language");
        window.sessionStorage.removeItem("evenstad-chat-history");
        window.sessionStorage.removeItem("evenstad-chat-message-groups");

        this.welcomeMessages = [];
        this.welcomeMessageContexts = [];
        this.popUpMessage = {};
        this.settings.sessionId = null;
        this.$refs.chatbox.$refs.messageBox.messages = [];
        this.$refs.chatbox.$refs.messageBox.botMessageBuffer = [];
        this.$refs.chatbox.$refs.messageBox.messsageGroups = [];
        this.$refs.chatbox.$refs.messageBox.botRespondingTimer = null;
        this.$refs.chatbox.$refs.messageBox.loadingMessages = false;
        this.$refs.chatbox.menuOpen = false;

        alert("All data successfully deleted!");

        this.handshake();
      };

      const failureCB = (error) => {
        alert("Unknown error occured: " + error + ". Please contact tehnical support at info@evenstad.ee to remove your data.");
      }

      this.deleteConversations(this.settings.assistantId,this.settings.sessionId,successCB, failureCB);



    },
    toggleChat(){
      this.isChatVisible = !this.isChatVisible;
      if(this.isChatVisible) this.$root.$cookies.set("evenstad-chat-open", "true","20min");
      else this.$root.$cookies.remove("evenstad-chat-open");
    },
    closeChat(){
      this.isChatVisible = false;
      this.$root.$cookies.remove("evenstad-chat-open");
    },
    cleanWelcomeMessages(){
      this.welcomeMessages = [];
      this.welcomeMessageContexts = [];
    },
    welcomeMessagesCreated(messages){
      let firstMessage = messages[0];
      
      if(this.hasBeenOpened === true) return;
      if(firstMessage.type != "text") return;

      this.popUpMessage = {
        id: uuidv4(),
        type: "simple",
        created: new Date(),
        isBotResponse: false,
        contextStrings: {},
        contents: {
          text: firstMessage.text
        }
      };
    },
    handshake(){
      const successCB = (data) => {
          let newSessionId = false;
          if(data.sessionId != this.settings.sessionId){
            window.sessionStorage.setItem("evenstad-chat-session-id", data.sessionId);
            this.settings.sessionId = data.sessionId;
            newSessionId = true;
          }

          this.settings.defaultLang = data.defaultLang;
          this.settings.allowedLangs = data.allowedLangs;
          this.settings.suggestionBehaviour = data.suggestionBehaviour;

          //Maybe we do not support the language of current page, fallback to default
          if(!this.settings.allowedLangs.find(x => x === this.settings.language)){
            this.settings.language = this.settings.defaultLang;
          }

          this.chatSettings = data.chatSettings;
          this.welcomeIntentId = data.welcomeIntentId;
          


          if((newSessionId) || (process.env.VUE_APP_PROJECT_ID && process.env.VUE_APP_PROJECT_ID == 'testing') || process.env.NODE_ENV == 'development' || this.developmentMod){
            this.fetchWelcomeMessages();
          }
          this.successfulHandshake = true;
      }

      const failureCB = (error) => {
        console.log(error);
      }

      this.postHandshake(this.settings.assistantId,this.settings.sessionId,successCB,failureCB);
    },
    fetchWelcomeMessages(){
      const successCB = (data) => {
        this.welcomeMessages = data.messages;
        this.welcomeMessageContexts = data.contextStrings;
        this.welcomeMessagesCreated(data.messages);
      }

      const failureCB = (error) => {
        console.log(error);
      }

      this.postIntentQuery(this.settings.assistantId,this.settings.sessionId,"",this.settings.language,this.welcomeIntentId, {},successCB,failureCB);
    },
    fetchLanguage(){
      let language = window.sessionStorage.getItem("evenstad-chat-language");

      if(language != null){
        this.settings.language = language;
        this.userHasSelectedLang = true;
        return;
      }

      //User has not selected language, let's work it out based on the page
      let possibleLang = document.getElementsByTagName("html")[0].lang;
      if(possibleLang && possibleLang.length == 2){
        this.settings.language = document.getElementsByTagName("html")[0].lang;
        return;
      }
      else if(possibleLang && possibleLang.length == 5){
        this.settings.language = possibleLang.substring(0,2);
        return;
      }

      possibleLang = this.getMeta("og:locale");
      if(possibleLang && possibleLang.length == 5){
        this.settings.language = possibleLang.substring(0,2);
        return;
      }

      //Fallback, if everything else fails
      this.settings.language = 'en';
    },
    changeLanguage(lang){
      this.settings.language = lang;
      this.userHasSelectedLang = true;

      window.sessionStorage.setItem("evenstad-chat-language", lang);
    },
    getMeta(metaProperty) {
      const metas = document.getElementsByTagName('meta');

      for (let i = 0; i < metas.length; i++) {
        if (metas[i].getAttribute('property') === metaProperty) {
          return metas[i].getAttribute('content');
        }
      }

      return '';
    },
    runAction(action){
      this.integrations.run(this,action.integration,action.function,action.parameters);
    }
  },
  mounted() {
    if(this.$root.$cookies.isKey("evenstad-chat-open")){
      this.isChatVisible = true;
    }
  },
  created() {
    if(process.env.NODE_ENV == "development"){
      console.log("Environment variables: ");
      console.log(process.env);
    }
    
    //********************
    this.fetchLanguage();

    //console.log("language: " + this.settings.language);
    let sessionId = window.sessionStorage.getItem("evenstad-chat-session-id");
    if(sessionId != null && sessionId.length > 0) this.settings.sessionId = sessionId;
    this.handshake();
  },
  watch: {
    isChatVisible: function() {
      //Let's remove any welcome messages if the chat has already been opened at that point
      if(this.isChatVisible){
        this.hasBeenOpened = true;
      }
    },
    'settings.language': function(){
      this.i18n.setLanguage(this.settings.language);
    },
    'settings.defaultLang': function(){
      this.i18n.setDefaultLanguage(this.settings.defaultLang);
    }
  }
}
</script>


<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
#evenstad-chat{
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}


h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}


img{
  max-height: 25vh;
}
</style>
