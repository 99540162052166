<template>
    <div class="inner-box">
      <div class="header-box">
          <div class="title-container">
              <h2>{{i18n.$t('popup_menu_header')}}</h2>
          </div>
          <div class="additional-info">
              <!-- <a class="icon-button" @click.stop="$emit('open-menu')">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z" /></svg>
              </a> -->
              <a class="icon-button" @click.stop="$emit('open-menu')">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 24 24"><path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" /></svg>
              </a>
          </div>
      </div>
      <div class="action-buttons">
        <a class="action-button" :href="baseServerAddress + '/' + settings.assistantId + '/gdpr/conversations/' + settings.sessionId" target="_blank">{{i18n.$t('view_conversation_history')}}</a>
        <a class="action-button" @click.stop="$emit('delete-history')">{{i18n.$t('delete_conversation_history')}}</a>
      </div>
  </div>
</template>

<script>
import { useI18n } from '../plugins/i18n';

export default {
  name: "MenuPopup",
  setup(){
    const i18n = useI18n();

    return {i18n}
  },
  props: {
    chatSettings: Object,
    settings: Object,
    menuOpen: Boolean,
    baseServerAddress: String
  }

}
</script>

<style lang="scss">
.header-box {
  display: flex;
  flex: 0 0 56px;
  justify-content: center;
  align-items: center;

  border: none;
  //border-bottom: 1px solid lightgray;

  background: $title-bar-background;

  //Evenstad Bot Custom
  //background: linear-gradient(270deg, #304352 0%, #3a3938 100%);
  /* -webkit-box-shadow: 0px 7px 4px 0px rgba(212,49,41,1); */
  -moz-box-shadow: 0px 7px 4px 0px rgba(212,49,41,1);
  box-shadow: 0px 2px 4px 3px rgba(0,0,0, 0.1);
  z-index: 3;
}

.header-box h2{
  margin: 0;
}

.action-buttons {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

a.action-button{
  color: $suggestion-text-color;
}

a.action-button:hover{
  color: $suggestion-text-color;
}

.action-button {

  text-decoration: none;
  max-width: 80%;
  max-height: 50vh;

  padding: 0.5em;
  margin: 0em;
  margin-bottom: 0.5em;
  border-radius: 10px;
  transition: opacity 0.25s ease, box-shadow 0.1s ease-out, transform 0.1s ease-out;
  font-size: 16px;
  color: $bot-text-color;
  /* Wordpress fixes */
  margin-block-start: 1em;
  margin-block-end: 1em;
  line-height: 1.5;
  margin: 0.25em 0;
  width: 80%;
  margin-bottom:0.25em;
  //margin-bottom:0.5em;
  border: 2px solid black;
  border-radius: 0px;

  cursor: pointer;
  background: $suggestion-background;
  transform: scale(1);
  text-align: center;
}

.action-button:hover{
  transform: scale(1.03);
  transform-origin: center;
  // -webkit-box-shadow: inset -5px 0px 15px -7px rgba(255,255,255,1);
  // -moz-box-shadow: inset -5px 0px 15px -7px rgba(255,255,255,1);
  // box-shadow: inset -5px 0px 15px -7px rgba(255,255,255,1);
}


.icon-button {
  width: 1.5em;
  height: 1.5em;
  margin-right: 15px;

  text-decoration: none;
  color: $bot-text-color;
  cursor: pointer;
}

.icon-button svg{
  fill: $title-color;
}

.icon-button svg:hover {
  transition: color 0.1s;
  fill: $bot-text-color-highlight;
  cursor: pointer;
}

h2 {
  margin: 0;
  color: $title-color;
  /* Wordpress fixes */
  font-size: 1.5em;
}

.title-container {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.additional-info {
  display: flex;
  flex-direction: row;
  position: absolute;
  right: 0;
  height: fit-content;
  z-index: 3;
}


/* Should trigger when people open their keyboards */
@media only screen and (orientation: landscape) and (max-height: 120pt){
  .header-box{
    display: none;
  }
}

/* Should trigger when people open their keyboards */
@media only screen and (orientation: landscape) and (max-height: 300pt) and (min-height:120pt){

  .additional-info{
    top: 15px;
  }
  
}

</style>