<template>
  <div ref="messageBox" class="message-box">

    <transition-group name="list" tag="div">
      <Message

        v-for="(message, index) in shownMessages"
        :key="message.id"
        :message="message"
        :index="index"
        @submit-query="userSubmitQuery"
        @submit-suggestion="userSubmitSuggestion"
        @select-intent="userSelectIntent"
        @select-action="userSelectAction"
        v-on:message-created="messageCreated"
      />
    </transition-group>
    <!-- <EmailMessage :parameters="{user_email:'test@test.ee',success_message:'Sent!'}"/> -->
  </div>

</template>

<script>

import Message from "./Message";
// import EmailMessage from "./EmailMessage";
import ApiClient from "../helpers/ApiClient"
import { v4 as uuidv4 } from 'uuid';

export default {
  name: "MessageBox",
  mixins: [
    ApiClient
  ],
  components: {
    Message,
    // EmailMessage
  },
  props: {
    isChatVisible: Boolean,
    settings: Object,
    chatSettings: Object,
    menuOpen: Boolean,
    newTextQuery: String,
  },
  data() {
    return{
        messages: [
      ],
      botMessagesBuffer: [],
      botRespondingTimer: null,
      loadingMessages: false,

    }
  },
  methods: {
    userSubmitQuery(query) {
        //this.$emit("submit-query", query);
        if(!this.chatSettings.underMaintenance){
          this.cleanbotMessagesBuffer();

          this.recheckAllSuggestionsAfterInteraction('textInput');

          this.createTextMessage(false, query);

          this.sendQuery(query);
        }

    },
    userSubmitSuggestion(suggestion){
      //
      if(!this.chatSettings.underMaintenance){
        this.cleanbotMessagesBuffer();

        this.recheckAllSuggestionsAfterInteraction('suggestionSelected');

        this.createTextMessage(false, suggestion.fullMessage);

        this.sendQuery(suggestion.query);
      }
    },
    userSelectIntent(intent){
      this.cleanbotMessagesBuffer();
      this.recheckAllSuggestionsAfterInteraction('suggestionSelected');
      this.createTextMessage(false, intent.text);
      if(intent.parameters){
        this.selectIntent(intent.intentId, intent.parameters);
      }
      else{
        this.selectIntent(intent.intentId, {}); 
      }

    },
    userSelectAction(action, index){
      this.cleanbotMessagesBuffer();
      this.recheckAllSuggestionsAfterInteraction('suggestionSelected');
      //this.createTextMessage(false, intent.text);

      //IMPLEMENT ACTION HANDLING HERE

      this.selectAction(action, index);

    },
    /* InteractionType - string, possible values: 'textInput', 'suggestionSelected'
    */
    cleanbotMessagesBuffer(){
      this.botMessagesBuffer = [];
      this.loadingMessages = false;
      //Removes loading message
    },
    loadMessageFromBuffer(){
      //var d = new Date();
      //const start = d.getTime();

      //The idea is to output all suggestions all at once, everything else with delay as intended
      let suggestionFlow = true;
      while(suggestionFlow){

        if(this.botMessagesBuffer.length == 0){
          this.loadingMessages = false;
          return;
        }
        let message = this.botMessagesBuffer[0];

        this.messages.push(message);
        
        this.botMessagesBuffer.splice(0,1);


        if(message.type != "suggestion" && message.type != "intent") suggestionFlow = false;
      }

      if(this.botMessagesBuffer.length == 0){
        this.cleanbotMessagesBuffer();
      }
      if(this.chatSettings.delayPerMessageMs > 0){
        setTimeout(()=> this.loadMessageFromBuffer(),this.chatSettings.delayPerMessageMs);
      }
      else{
        this.loadMessageFromBuffer();
      }
      //d = new Date();
      //const end = d.getTime();
      //console.log("Handler took " + (end-start) + " ms ");
    },
    startMessageLoading(){
      this.loadingMessages = true;
      if(this.chatSettings.delayPerMessageMs > 0){
        setTimeout(()=> this.loadMessageFromBuffer(),this.chatSettings.delayPerMessageMs);
      }
      else{
        this.loadMessageFromBuffer();
      }
    },
    recheckAllSuggestionsAfterInteraction(interactionType){
      //We need to find all suggestions and 
      for(let a = 0; a < this.messages.length; a++){
        if(this.messages[a].type != "suggestion" && this.messages[a].type != "intent") continue;

        let msgObj = this.messages[a];

        if(msgObj.suggestionBehaviour){
          if(msgObj.suggestionBehaviour == 'hideAllOnInput'){
            this.messages.splice(a,1);
            a--;
            continue;        
          }
          else if(msgObj.suggestionBehaviour == 'hideAllOnSelect' && interactionType == 'suggestionSelected'){
            this.messages.splice(a,1);
            a--;
            continue;           
          }
          else if(msgObj.suggestionBehaviour == 'keepAllOnInput'){
            continue;
          }
        }
      }
    },
    messageCreated() {
      this.$refs.messageBox.scrollTop = this.$refs.messageBox.scrollHeight;
    },
    createTextMessage(isBotResponse, text, contextStrings = []) {
      const message = {
        id: uuidv4(),
        type: "simple",
        created: new Date(),
        isBotResponse: isBotResponse,
        contextStrings: contextStrings,
        contents: {
          text: text
        }
      };

      if(isBotResponse){
        this.botMessagesBuffer = [...this.botMessagesBuffer, message];
      }
      else{
        this.messages = [...this.messages, message];   
      }
    },
    createSuggestionMessage(suggestion, contextStrings = [], suggestionBehaviour) {
      const message = {
        id: uuidv4(),
        type: "suggestion",
        created: new Date(),
        isBotResponse: true,
        suggestionBehaviour: suggestionBehaviour,
        contextStrings: contextStrings,
        contents: {
          suggestion: suggestion
        }
      };

      this.botMessagesBuffer = [...this.botMessagesBuffer, message];
    },
    createLinkMessage(link, contextStrings = []) {
      const message = {
        id: uuidv4(),
        type: "link",
        created: new Date(),
        isBotResponse: true,
        contextStrings: contextStrings,
        contents: {
          link: link
        }
      };

      this.botMessagesBuffer = [...this.botMessagesBuffer, message];
    },
    createIntentMessage(intent, contextStrings = [], suggestionBehaviour){

      const message = {
        id: uuidv4(),
        type: "intent",
        created: new Date(),
        isBotResponse: true,
        suggestionBehaviour: suggestionBehaviour,
        contextStrings: contextStrings,
        contents: {
          intent: intent
        }
      }

      this.botMessagesBuffer = [...this.botMessagesBuffer, message];
    },
    createActionMessage(action, contextStrings = [], suggestionBehaviour){
      const message = {
        id: uuidv4(),
        type: "action",
        created: new Date(),
        isBotResponse: true,
        suggestionBehaviour: suggestionBehaviour,
        contextStrings: contextStrings,
        contents: {
          action: action
        }
      }

      this.botMessagesBuffer = [...this.botMessagesBuffer, message];
    },
    findLatestContextString(messages){
      for(let i = messages.length-1; i>=0; i--){
        let message = messages[i];
        if(!message.isBotResponse) continue;
        return message.contextStrings;
      }

      return [];
    },
    sendQuery(query /*, intent, metainfo*/) {
      if(this.isChatVisible) this.$root.$cookies.set("evenstad-chat-open", "false","20min");
      if (query == null) {
        return;
      }
      let contextStrings = this.findLatestContextString(this.messages);

      const successCB = (data) => {
        this.processResponse(data);
      }

      const failureCB = (error) => {
        console.log(error);
      }

      this.postQuery(this.settings.assistantId,this.settings.sessionId,contextStrings,this.settings.language,query,successCB,failureCB);
    },
    selectIntent(intentId, parameters) {
      if(this.isChatVisible) this.$root.$cookies.set("evenstad-chat-open", "false","20min");
      if (intentId == null) {
        return;
      }
      let contextStrings = this.findLatestContextString(this.messages);

      const successCB = (data) => {
        this.processResponse(data);
      }

      const failureCB = (error) => {
        console.log(error);
      }

      this.postIntentQuery(this.settings.assistantId,this.settings.sessionId,contextStrings,this.settings.language,intentId, parameters, successCB,failureCB);
    },
    selectAction(action, index) {
      if(this.isChatVisible) this.$root.$cookies.set("evenstad-chat-open", "false","20min");
      this.$emit('run-action',action);
      console.log(action);
      console.log(index);

      const successCB = (data) => {
        if(data.success){
          if(action.integration === 'email' && action.function === 'show_form'){
            const newMsg = {
              id: uuidv4(),
              type: "simple",
              created: new Date(),
              isBotResponse: true,
              contextStrings: this.findLatestContextString(this.messages),
              contents: {
                text: action.parameters.success_message
              }
            };
            this.messages.splice(index,1, newMsg);
          }
        }
        else{
            const newMsg = {
              id: uuidv4(),
              type: "simple",
              created: new Date(),
              isBotResponse: true,
              contextStrings: this.findLatestContextString(this.messages),
              contents: {
                text: action.parameters.failure_message
              }
            };
            this.messages.splice(index,1, newMsg);
        }
        //this.processResponse(data);
      }

      const failureCB = (error) => {
        const newMsg = {
          id: uuidv4(),
          type: "simple",
          created: new Date(),
          isBotResponse: true,
          contextStrings: this.findLatestContextString(this.messages),
          contents: {
            text: action.parameters.failure_message
          }
        };
        this.messages.splice(index,1, newMsg);
        console.log(error);
      }

      this.postAction(this.settings.assistantId,this.settings.sessionId,this.settings.language,action,successCB,failureCB);
    },
    processResponse(data){
      this.createNewMessages(data.messages,data.contextStrings,data.suggestionBehaviour);
      if(!this.loadingMessages){
        this.startMessageLoading();
      }
    },
    createNewMessages(messages, contextStrings, suggestionBehaviour){
      for(let i=0; i< messages.length;i++){
        let message = messages[i];

        switch(message.type){
          case 'text':
            this.createTextMessage(true, message.text, contextStrings);
            break;
          case 'suggestion':
            this.createSuggestionMessage(message, contextStrings, suggestionBehaviour);
            break;
          case 'intent':
            this.createIntentMessage(message, contextStrings, suggestionBehaviour);
            break;
          case 'action':
            this.createActionMessage(message, contextStrings, suggestionBehaviour);
            break;
          case 'link':
            this.createLinkMessage(message, contextStrings);
            break;
        }
      }
      if(!this.loadingMessages){
        //console.log("Starting to load messages");
        this.startMessageLoading();
      }
    }
  },
  watch: {
      newTextQuery: function() {
        if(this.newTextQuery == null || this.newTextQuery.length <= 0){
            return;
        }
        this.userSubmitQuery(this.newTextQuery);
        this.$emit('user-query-created');
      },
      messages: function() {
        if(this.messages != null){
          window.sessionStorage.setItem("evenstad-chat-history",JSON.stringify(this.messages));
        }
      }
  },
  created(){
    //Thought for future - should we implement versioning here? Chat History could be using older X format, while Messages component might be using newer Y format
    let history = JSON.parse(window.sessionStorage.getItem("evenstad-chat-history"));
    if(history != null){
      this.messages = history;
    }
  },
  computed: {
    shownMessages: function(){
      let messages = this.messages.slice();
      if(this.chatSettings.underMaintenance){
        messages = [];
        messages.push({
          id: "unavailableMessage",
          type: 'simple',
          created: new Date(),
          isBotResponse: true,
          contents:{
            text: this.chatSettings.maintenanceNotice
          }
        });

        return messages;
      }
      if(this.loadingMessages){
        messages.push({
          id: "loadingMsg",
          type: 'loading',
          created: new Date(),
          isBotResponse: true,
          contents:{
            text: "..."
          }
        });
      }
      return messages;
    }
  }
};
</script>

<style lang="scss" scoped>
.message-box {
  position: relative;
  flex: 1 1 auto;
  padding: 0 0.5em;
  padding-top: 0.5em;
  scroll-behavior: smooth;

  border: none;

  overflow-y: auto;
  overflow-x: hidden;
  background: $message-box-color;
}
</style>