<template>
    <div class="text-input">
        <div class="resizeable-textarea">
            <textarea ref="textInput" :placeholder="i18n.$t('user_query_input')" maxlength="150" v-model="input" @keydown.enter.exact="submitQuery"/>
        </div>
        
        <LanguageSwitcher
            v-if="settings.allowedLangs.length > 1"
            :settings="settings"
            @change-language="changeLanguage"
        />
        <a class="submit-button" alt="Submit query" @click="submitQuery">
            <svg enable-background="new 0 0 448.011 448.011" version="1.1" viewBox="0 0 448.01 448.01" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">	<path d="m438.73 209.46-416-192c-6.624-3.008-14.528-1.216-19.136 4.48-4.64 5.696-4.8 13.792-0.384 19.648l136.8 182.4-136.8 182.4c-4.416 5.856-4.256 13.984 0.352 19.648 3.104 3.872 7.744 5.952 12.448 5.952 2.272 0 4.544-0.48 6.688-1.472l416-192c5.696-2.624 9.312-8.288 9.312-14.528s-3.616-11.904-9.28-14.528z"/></svg>
        </a>
    </div>
</template>

<script>
import LanguageSwitcher from "./LanguageSwitcher";
import {useI18n} from '../plugins/i18n';
//import { setResizeListeners } from "../helpers/auto-resize.js";

export default {
    name: 'TextInput',
    setup() {
        const i18n = useI18n();
        return {i18n};
    },
    components: {
        LanguageSwitcher
    },
    props: {
        settings: Object
    },
    data() {
        return{
            autoGrow: true,
            noResize: false,
            rowHeight: 16,
            rows: 1,
            input: ''
        }
    },
    methods: {
        submitQuery(e){
            e.preventDefault();
            //Form validation
            if(this.input.length == 0 || this.input.length > 255){
                return;
            }
            const inputString = this.input;
            this.$emit('submit-query', inputString);

            this.input = '';
        },
        calculateInputHeight () {
            const input = this.$refs.textInput;
            if (!input) return

            input.style.height = '0'
            const height = input.scrollHeight;
            const minHeight = parseInt(this.rows, 10) * parseFloat(this.rowHeight);
            // This has to be done ASAP, waiting for Vue
            // to update the DOM causes ugly layout jumping
            input.style.height = Math.max(minHeight, height) + 'px'
        },
        onInput () {
            this.autoGrow && this.calculateInputHeight()
        },
        changeLanguage(lang){
            this.$emit('change-language', lang);
        }
    },
    computed: {
        noResizeHandle (){
            return this.noResize || this.autoGrow
        }
    },
    mounted() {
        setTimeout(() => {
        this.autoGrow && this.calculateInputHeight()
        }, 0)
    },
    watch: {
        input () {
        this.autoGrow && this.$nextTick(this.calculateInputHeight)
        },
    },
}
</script>

<style lang="scss" scoped>

    .language-switcher span{
        padding: 4px 8px;
    }

    .text-input{
        display: flex;
        flex: 0 0 auto;
        align-items:center;
        justify-content: center;
        // height: 18%;
        padding: 0.5em;

        //border-top: 1px solid lightgray;

        background: $input-box-color;
    }

        /* Should trigger when people open their keyboards */
    @media only screen and (orientation: landscape) and (max-height: 120pt){
        textarea{
            height: 90%;
            font-size: 24px;
        }
    }


    /* Should trigger when people open their keyboards */
    @media only screen and (orientation: landscape) and (max-height: 300pt) and (min-height:120pt){
        textarea{
            height: 3em;
        }
    }

    .resizeable-textarea {
        flex: 1;
    }

    textarea {
        color: #000;
        overflow: hidden;

        float:left;
        width: 100%;
        margin: 0;
        padding: 0;
        padding-left: 10px;

        border: none;

        text-align: left;
        vertical-align: middle;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 16px;
        font-weight: 100;

        background: transparent;
        color: $input-text-color;
        resize: none;

        /* Wordpress fixes */
        height: 1.1em;
        line-height: 1;
        letter-spacing: initial;
    }

    textarea:focus, input[type=text]:focus, input[type=submit]:focus{
        outline: none;
    }

    .submit-button {
        cursor: pointer;

        transition: transform 0.1s ease-out;
        /* WordPress fixes */
        padding: 0;
        margin: 8px;
    }

    .submit-button svg{
        height: 1.5em;
        fill: $send-query-button;
    }

    .submit-button:hover{
        transform: scale(1.1);
        transform-origin: center;
        // -webkit-box-shadow: inset -5px 0px 15px -7px rgba(255,255,255,1);
        // -moz-box-shadow: inset -5px 0px 15px -7px rgba(255,255,255,1);
        // box-shadow: inset -5px 0px 15px -7px rgba(255,255,255,1);
    }
    

</style>