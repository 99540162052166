export function show(){
    if(!window.zE) return false;

    window.zE('webWidget', 'show');

    return true;
}

export function hide(){
    //console.log(window.zE);
    if(!window.zE) return false;

    window.zE('webWidget', 'hide');

    return true;
}

export function open(){
    //console.log(window.zE);
    if(!window.zE) return false;

    window.zE('webWidget', 'open');

    return true;
}

export function send(message){
    //console.log(window.zE);
    if(!window.zE) return false;

    window.zE('webWidget', 'chat:send',message);

    return true;
}