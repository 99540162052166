<script>
import axios from 'axios';


export default {
    name: 'ApiClient',
    data(){
        return{
            baseServerAddress: process.env.VUE_APP_BASE_SERVER_ADDRESS,
            errorMessages: {
                serverAddressNotSet: "Server address has not been set! You can change it by clicking on settings icon on Login page corner",
                malformedRequest: "Server was not able to process your request. Please check for any anomalous input and try again!",
                sessionExpired: "Your session has expired and you need to login again to proceed!",
                serverDidNotRespond: "Server did not respond within time. Please check your internet connection!",
                unknownError: "Something went very wrong, please contact technical support!",
                apiHasChanged: "It seems you have an older version of the console. Please contact technical support!",
                endpointNotFound: "It seems that the functionality has not been implemented yet.",
                serverError: "Server has encountered an internal error. Please wait a bit and try again!"
            }
        }
    },
    methods: {
        postHandshake(assistantId, sessionId, successCB, failureCB){
            axios
            .post(this.baseServerAddress + "/" + assistantId + "/handshake", {
                sessionId: sessionId,
                userAgent: navigator.userAgent,
                userURL: window.location.href
                })
            .then(response => {
                if(!response.data) failureCB(this.error.unknownError);
                if(!response.data.sessionId) failureCB("Server did not return a session ID");
                successCB(response.data);
            })
            .catch(error => {
                failureCB(error);
            });
        },
        postQuery(assistantId, sessionId, contextStrings, language, query, successCB, failureCB /*, intent, metainfo*/) {
            axios
            .post(this.baseServerAddress + "/" + assistantId + "/query", {
                sessionId: sessionId,
                query: query,
                contextStrings: contextStrings,
                language: language
            })
            .then(response => {
                if(!response.data) failureCB(this.errorMessages.unknownError);
                if(!response.data.messages) failureCB(this.errorMessages.unknownError);

                if(!Array.isArray(response.data.messages)){
                    failureCB(this.errorMessages.unknownError);
                }

                successCB(response.data);
            })
            .catch(error => {
                failureCB(error);
            });
        },
        postIntentQuery(assistantId, sessionId, contextStrings, language, intentId, parameters, successCB, failureCB /*, intent, metainfo*/) {
            axios
            .post(this.baseServerAddress + "/" + assistantId + "/query", {
                type: "intent",
                sessionId: sessionId,
                query: intentId,
                contextStrings: contextStrings,
                parameters: parameters,
                language: language
            })
            .then(response => {
                if(!response.data) failureCB(this.errorMessages.unknownError);
                if(!response.data.messages) failureCB(this.errorMessages.unknownError);

                if(!Array.isArray(response.data.messages)){
                    failureCB(this.errorMessages.unknownError);
                }

                successCB(response.data);
            })
            .catch(error => {
                failureCB(error);
            });
        },
        postAction(assistantId, sessionId, language, action, successCB, failureCB) {
            axios
            .post(this.baseServerAddress + "/" + assistantId + "/action", {
                integration: action.integration,
                function: action.function,
                parameters: action.parameters,
                sessionId: sessionId,
                language: language
            })
            .then(response => {
                if(!response.data || !response.data.success) failureCB(this.errorMessages.unknownError);
                successCB(response.data);
            })
            .catch(error => {
                failureCB(error);
            });
        },
        deleteConversations(assistantId, sessionId, successCB, failureCB){
            axios
            .delete(this.baseServerAddress + "/" + assistantId + "/gdpr/conversations/" + sessionId,{
                data: {
                }
            })
            .then(response => {
                if(!response.data) failureCB(this.errorMessages.unknownError);
                if(!response.data.deleted) failureCB(this.errorMessages.unknownError);
                successCB();
            })
            .catch(error => {
                failureCB(error);
            });
        },
        getViewConversations(assistantId, sessionId, successCB, failureCB){
            axios
            .get(this.baseServerAddress + "/" + assistantId + "/gdpr/conversations/" + sessionId)
            .then(response => {
                if(!response.data) failureCB(this.errorMessages.unknownError);
                if(!response.data.conversations) failureCB(this.errorMessages.unknownError);
                successCB(response.data);
            })
            .catch(error => {
                failureCB(error);
            });
        }
    }
}
</script>