<template>
<div class="dropdown-container">
    <div class="dropdown" :class="[isOpen ? 'active' : '']">
        <span class="selLabel" @click="switchDropdown">{{languageLabel}}</span>
        <input type="hidden" name="cd-dropdown">
        <ul class="dropdown-list">
            <li v-for="lang in selectableLanguages" :key="lang.code" @click="selectLang(lang.code)">
                <span>{{lang.label}}</span>
            </li>
        </ul>
    </div>
</div>
</template>

<script>
export default {
    name: "LanguageSwitcher",
    data () {
        return {
            isOpen: false,
            languageLabels: [
                {
                    code: 'en',
                    label: 'ENG'
                },
                {
                    code: 'et',
                    label: 'EST'
                },
                /*{
                    code: 'ru',
                    label: 'RUS'
                }*/
            ]
        }
    },
    props: {
        settings: Object
    },
    methods: {
        switchDropdown(){
            this.isOpen = !this.isOpen;
        },
        selectLang(code){
            this.$emit('change-language', code);
            this.isOpen = false;
        }
    },
    computed: {
        languageLabel: function () {
            let match = this.languageLabels.find(x => x.code == this.settings.language);
            if(match) return match.label;

            return this.languages[0].label;
        },
        selectableLanguages: function() {
          let langs = [];
          for(let i = 0; i < this.settings.allowedLangs.length; i++){
            let match = this.languageLabels.find(x => x.code == this.settings.allowedLangs[i]);
            if(match){
              langs.push(match);
            }
            else{
              langs.push({
                code: this.settings.allowedLangs[i],
                label: this.settings.allowedLangs[i]
              });
            }
          }
          return langs;
        }
    }
}
</script>

<style lang="scss">
.dropdown-container{
    z-index: 20;
}

.dropdown {
  margin: 0;
  width: 4.5em;
  
  position: relative;
  perspective: 800px;
  &.active {
    .selLabel { 
      &:after {
        content: '\25BC';
      }
    }
    .dropdown-list {
      li {
        
        &:nth-child(1) {
          transform: translateY(-100%);
        }
        &:nth-child(2) {
          transform: translateY(-200%);
        }
        &:nth-child(3) {
          transform: translateY(-300%);
        }
        &:nth-child(4) {
          transform: translateY(-400%);
        }
      }
    }
  }
  > span {
    /*box-shadow: 0 1px 1px rgba(0,0,0,0.1);*/
    width: 100%;
    height: 30px;
    line-height: 30px;
    color: #757575;
    font-size: 18px;
    letter-spacing: 2px;
    background: #ffffff;
    display: block;
    padding-left: 0.5em;
    position: relative;
    z-index: 9999;
    cursor: pointer;
    

    transform-style: preserve-3d;
    transform-origin: 50% 0%;
    transition: transform 300ms;
    -webkit-backface-visibility: hidden;
    -webkit-touch-callout: none;
    user-select: none;
    
    &:after {
      content: '\25B2';
      position: absolute;
      right: 0px;
      top: 15%;
      width: 2em;
      text-align: center;
      font-size: 12px;
      padding: 10px 5px;
      height: 70%;
      line-height: 2px;
    }
    &:active {
      transform: rotateX(45deg);
      &:after {
        content: '\25BC';
      }
    }
  }
}
.dropdown-list {
  position: absolute;
  top: 0px;
  width: 100%;
  margin: 0;
  li {
    background: #FFF;
    display: block;
    list-style: none;
    left: 0;
    opacity: 1;
    transition: transform 300ms ease;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 0;
    width: 100%;
    &:nth-child(1) {
      z-index: 14;
      transform: translateY(0);
    }
    &:nth-child(2) {
      z-index: 13;
      transform: translateY(0);
    }
    &:nth-child(3) {
      z-index: 12;
      transform: translateY(0);
    }
    &:nth-child(4) {
      z-index: 11;
      transform: translateY(0);
    }
    span {
      /*box-shadow: 0 1px 1px rgba(0,0,0,0.2);*/
      -webkit-backface-visibility: hidden;
      -webkit-touch-callout: none;
      user-select: none;

      width: 100%;
      font-size: 18px;
      line-height: 30px;
      padding: 0 0.5em;
      display: block;
      color: rgb(0, 0, 0);
      cursor: pointer;
      letter-spacing: 2px;
    }
  }
}
</style>