import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'
import EvenstadChat from './EvenstadChat.vue'
import VueCookies from 'vue-cookies';

Vue.config.productionTip = false
Vue.use(VueCookies);
Vue.use(VueCompositionAPI);

window.chatComponent = new Vue({
  render: h => h(EvenstadChat),
})

if(document.getElementById('evenstad-chat')){
  window.chatComponent.$mount(('#evenstad-chat'))
}
else{
  window.chatComponent.$mount()
}


