<template>
  <div v-if="message.type === 'simple'" class="message" v-bind:class="[message.type, message.isBotResponse ? 'bot-response' : ''] ">
    <p>{{message.contents.text}}</p>
  </div>
  <div v-else-if="message.type === 'loading'" class="message" v-bind:class="[message.type, message.isBotResponse ? 'bot-response' : ''] ">
    <p>{{message.contents.text}}</p>
  </div>
  <div v-else-if="message.type === 'suggestion'" class="message" v-bind:class="[message.type, message.isBotResponse ? 'bot-response' : ''] "  v-on:click="suggestionSelected()">
    <p>{{message.contents.suggestion.suggestionLabel}}</p>
  </div>
  <div v-else-if="message.type === 'intent'" class="message" v-bind:class="[message.type, message.isBotResponse ? 'bot-response' : ''] "  v-on:click="intentSelected()">
    <p>{{message.contents.intent.label}}</p>
  </div>
  <email-message v-else-if="message.type === 'action' && message.contents.action.integration === 'email' && message.contents.action.function === 'show_form'"
  :parameters="message.contents.action.parameters" @send="actionSelected()"/>
  <div v-else-if="message.type === 'action'" class="message" v-bind:class="[message.type, message.isBotResponse ? 'bot-response' : ''] "  v-on:click="actionSelected()">
    <p>{{message.contents.action.parameters.label ? message.contents.action.parameters.label : 'ACTION WITHOUT LABEL'}}</p>
  </div>
  <a v-else-if="message.type === 'link'" class="message" v-bind:class="[message.type, message.isBotResponse ? 'bot-response' : ''] " v-bind:href="message.contents.link.url" v-bind:target="[message.contents.link.openInNewTab === true ? '_blank' : '_self']">
    {{message.contents.link.title}}
  </a>
  <div v-else class="message" v-bind:class="[message.type, message.isBotResponse ? 'bot-response' : '']">
      <p>This is an unknown message</p>
  </div>
</template>

<script>
import EmailMessage from './EmailMessage.vue';
export default {
  components: { EmailMessage },
  name: "Message",
  props: {
    message: Object,
    index: Number
  },
  methods: {
    suggestionSelected() {
      if(this.message.type === 'suggestion'){
        this.$emit("submit-suggestion", this.message.contents.suggestion);
      }
    },
    intentSelected() {
      if(this.message.type === 'intent'){
        this.$emit("select-intent", this.message.contents.intent);
      }
    },
    actionSelected() {
      if(this.message.type === 'action'){
        this.$emit("select-action", this.message.contents.action, this.index);
      }
    }
  },
  mounted: function() {
    this.$emit("message-created");
  },
  created(){
  }
};
</script>

<style lang="scss" scoped>
.message {
  position:relative;
  float: right;
  width: fit-content;
  max-width: 80%;
  max-height: 50vh;

  padding: 0 1em;
  margin: 0em;
  margin-bottom: 0.5em;
  clear: both;

  border: none;
  border-radius: 10px;

  transition: opacity 0.25s ease, box-shadow 0.1s ease-out, transform 0.1s ease-out;

  font-size: 14px;
  background: $user-query-background;
  color: $bot-text-color;
  list-style: none;

  /* Wordpress fixes */
  margin-block-start: 1em;
  margin-block-end: 1em;
  line-height: 1.5;
  margin: 0.25em 0;

}

.message p{
  font-size: 1em;
}

.message.bot-response,.message.loading.bot-response {
  float: left;
  background: $bot-response-background;
}

//Little triangles for speech bubbles
.message.simple{
    margin-right:10px;
}

.message.simple:after {
	content: '';
	position: absolute;
	right: 0;
	top: 50%;
	width: 0;
	height: 0;
	border: 10px solid transparent;
	border-left-color:  $user-query-background;
	border-right: 0;
	margin-top: -10px;
	margin-right: -10px;
}

.message.simple.bot-response, .message.loading.bot-response{
    margin-left:10px;
}


.message.simple.bot-response:after, .message.loading.bot-response:after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-right-color: $bot-response-background;
    border-left: 0;
    margin-top: -10px;
    margin-left: -10px;

}

.message.suggestion p, .message.intent p, .message.action p{
  text-align: center;
  color: $suggestion-text-color;
  margin: 0.5em;
}


/* Style for suggestion type */
.message.suggestion, .message.intent, .message.action {
  
  width: 80%;
  padding: 0;
  margin-bottom:0.25em;
  margin-left: 10px;
  //margin-bottom:0.5em;


  border: 2px solid black;
  border-radius: 0px;

  cursor: pointer;
  background: $suggestion-background;

  transform: scale(1);
  text-align: center;
}

.message.suggestion:hover, .message.intent:hover, .message.action:hover{
  transform: scale(1.03);
  transform-origin: center;
  // -webkit-box-shadow: inset -5px 0px 15px -7px rgba(255,255,255,1);
  // -moz-box-shadow: inset -5px 0px 15px -7px rgba(255,255,255,1);
  // box-shadow: inset -5px 0px 15px -7px rgba(255,255,255,1);
}

.message.link {
  
  width: 80%;
  padding: 0;
  margin-bottom:0.25em;
  margin-left: 10px;
  padding: 0.5em 0em;
  //margin-bottom:0.5em;


  border: 0;
  border-radius: 10px;

  cursor: pointer;
  background: $suggestion-background;

  transform: scale(1);
  text-align: center;
}

.message.link:hover{
  transform: scale(1.03);
  transform-origin: center;
  // -webkit-box-shadow: inset -5px 0px 15px -7px rgba(255,255,255,1);
  // -moz-box-shadow: inset -5px 0px 15px -7px rgba(255,255,255,1);
  // box-shadow: inset -5px 0px 15px -7px rgba(255,255,255,1);
}

a.message.link{
  text-decoration: none;
  color: $suggestion-text-color;
}

a.message.link::after{
  font-size: 1em;
  content: " \00BB";
}

.message p {
  margin: 0.5em 0;
  text-align: left;
}

/* ************************* */

/* Transitions */
.message.list-leave-active {
  //position: absolute;
  //top: 0;
  //left: 0;
}

.message.list-enter,
.message.list-leave-to {
  transition: opacity 0.4s ease-in, max-height 0.5s ease-in, transform 0.3s ease-in 0.1s;
  transform: translateX(500px);
  opacity: 0;
  //max-height: 0%;
}

.message.list-enter:hover,
.message.list-leave-to:hover {
  transition: opacity 0.2s ease-in, max-height 0.5s ease-in, transform 0.3s ease-in;
  transform: translateX(500px);
  opacity: 0;
  //max-height: 0%;
}

.message.loading.list-enter,
.message.loading.list-leave-to {
  transition: none;
  opacity: 0;
  //max-height: 0%;
}

.message.loading.list-enter:hover,
.message.loading.list-leave-to:hover {
  transition: none;
  opacity: 0;
  //max-height: 0%;
}
/* ************************* */
</style>