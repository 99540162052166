import { provide, inject } from "@vue/composition-api";
import * as zD from "./zendesk/zendesk";
const initIntegrations = config => ({
  config: config,
  run(componentReference, integration, fnName, parameters){
    
    if(integration == 'zendesk'){
      if(fnName == 'show'){
        if(componentReference) componentReference.hidden = true;
        return zD.show();
      }
      else if(fnName == 'hide'){
        if(componentReference) componentReference.hidden = false;
        return zD.hide();
      }
      else if(fnName == 'open'){
        if(componentReference) componentReference.hidden = true;
        return zD.open();
      }
      else if(fnName == 'handover'){
        if(componentReference) componentReference.hidden = true;
        if(zD.show() && zD.open() && zD.send(parameters.message)) return true;
        return false;
      }
      else{
        return false;
      }
    }
    else{
      return false;
    }
  }
});

const integrationsSymbol = Symbol();

export function startIntegrations(config) {
  const integrations = initIntegrations(config);
  provide(integrationsSymbol, integrations);
}

export function useIntegrations() {
  const integrations = inject(integrationsSymbol);
  if (!integrations) throw new Error("Integrations have not been initialized");

  return integrations;
}