<template>
  <div id="evenstad-chat-toggle-button">
    <div class="inner-helper-container" style="position: relative;">
      <svg style="position: absolute;" width="70px" height="70px" viewBox="0 0 70 70">
        <svg x="0" y="0" width="70px" height="70px">
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g>
              <circle class="toggle-circle" fill="#000" cx="35" cy="35" r="35" />
            </g>
          </g>
        </svg>
      </svg>
    </div>
    <div class="second-helper">
      <img class="logo-icon" style="position: absolute;" src="https://denimdream.evenstad.ee/denimdream-icon.png">
    </div>
  </div>
</template>

<script>
export default {
  name: "ChatToggle",
  props:{
      imageURL: String,
      language: String
  }

};
</script>

<style scoped lang="scss">
    .logo-icon{
      width: 42px;
    }

    .second-helper{
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    #evenstad-chat-toggle-button{
        background: none;
        border-radius: 50%;
        bottom: 18pt;
        display: block;
        height: 50pt;
        padding: 0px;
        position: fixed;
        right: 18pt;
        top: auto;
        width: 50pt;
        z-index: 2147483646;
        cursor: pointer;
        box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    }


    @media only screen and (max-width: 333pt) {
      #evenstad-chat-toggle-button{
        display: none;
      }
      #evenstad-chat-toggle-button.enabled{
        display: inline;
      }
    }

    .toggle-circle{
      fill: #FFF;
      -webkit-box-shadow: 0px 7px 25px 1px rgba(0,0,0,0.5);
      -moz-box-shadow: 0px 7px 25px 1px rgba(0,0,0,0.5);
      box-shadow: 0px 7px 25px 1px rgba(0,0,0,0.5);
    }

    .toggle-icon{
      stroke: $toggle-circle-color;
      stroke-width: 5px;
      fill: $toggle-icon-color;
    }
</style>