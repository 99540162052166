export default function(){
    return {
        en: {
            user_query_input: "enter your question",
            popup_menu_header: "Settings",
            view_conversation_history: "View Conversation History",
            delete_conversation_history: "Delete Conversation History",
            invalid_email: "Please insert valid email!",
            invalid_body: "Please add a question!",
            body: "Question",
            email: "Email",
            send: "Send",
        },
        et: {
            user_query_input: "sisesta oma küsimus",
            popup_menu_header: "Sätted",
            view_conversation_history: "Vaata vestlusajalugu",
            delete_conversation_history: "Kustuta vestlusajalugu",
            invalid_email: "Palun sisesta korrektne email!",
            invalid_body: "Palun kirjuta natukene probleemist!",
            body: "Küsimus",
            email: "E-mail",
            send: "Saada",

        },
        ru: {
            user_query_input: "введите свой вопрос",
            popup_menu_header: "Настройки",
            view_conversation_history: "Просмотр истории разговоров",
            delete_conversation_history: "Удалить историю разговоров",
            invalid_email: "Please insert valid email!",
            invalid_body: "Please add a question!",
            body: "Question",
            email: "Email",
            send: "Send",

        }
    }
}