<template>
  <div class="header-box">
    <div class="bot-profile">
      <img v-bind:src="chatSettings.logoUrl" />
      <!-- <div class="status-light" /> -->
    </div>
    <div class="title-container">
      <h2>{{chatSettings.displayName}}</h2>
    </div>
    <div class="additional-info">
      <a class="icon-button" @click.stop="openMenu">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z" /></svg>
      </a>
      <a class="icon-button" @click.stop="closeChat">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 24 24"><path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" /></svg>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderBox",
  props: {
    chatSettings: Object,
    settings: Object,
    menuOpen: Boolean
  },
  methods: {
    closeChat() {
      this.$emit("close-chat");
    },
    openMenu() {
      this.$emit("open-menu");
    }
  }
};
</script>

<style lang="scss" scoped>
.header-box {
  display: flex;
  flex: 0 0 56px;
  justify-content: center;
  align-items: center;

  border: none;
  //border-bottom: 1px solid lightgray;

  background: $title-bar-background;

  //Evenstad Bot Custom
  //background: linear-gradient(270deg, #304352 0%, #3a3938 100%);
  /* -webkit-box-shadow: 0px 7px 4px 0px rgba(212,49,41,1); */
  -moz-box-shadow: 0px 7px 4px 0px rgba(212,49,41,1);
  box-shadow: 0px 2px 4px 3px rgba(0,0,0, 0.1);
  z-index: 3;
}


// .status-light {
//   position: absolute;
//   height: 10px;
//   width: 10px;
//   right: 10%;
//   bottom: 10%;

//   border: 1px solid black;
//   border-radius: 50%;

//   background: lawngreen;
// }
.icon-button {
  width: 1.5em;
  height: 1.5em;
  margin-right: 15px;

  text-decoration: none;
  color: $bot-text-color;
  cursor: pointer;
}

.icon-button svg{
  fill: $title-color;
}



.icon-button svg:hover {
  transition: color 0.1s;
  fill: $bot-text-color-highlight;
  cursor: pointer;
}



.bot-profile {
  position: absolute;
  left: 0;
  height: 48px;
  padding: 5px;
  padding-left: 1em;
}

.bot-profile img {
  height: 100%;
}


h2 {
  margin: 0;
  color: $title-color;
  /* Wordpress fixes */
  font-size: 1.5em;
}

.title-container {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.additional-info {
  display: flex;
  flex-direction: row;
  position: absolute;
  right: 0;
  height: fit-content;
  z-index: 3;
}


/* Should trigger when people open their keyboards */
@media only screen and (orientation: landscape) and (max-height: 120pt){
  .header-box{
    display: none;
  }
}

/* Should trigger when people open their keyboards */
@media only screen and (orientation: landscape) and (max-height: 300pt) and (min-height:120pt){

  .additional-info{
    top: 15px;
  }
  
}

</style>