<template>
<transition appear name="pop-in">
    <Message v-if="readyForPopup && Object.keys(message).length !== 0" id="welcome-message" v-bind:message="message">
    </Message>
</transition>
</template>

<script>
import Message from "./Message";

export default {
    name: 'WelcomeMessage',
    components: {
        Message
    },
    data() {
        return{
            readyForPopup: false
        }
    },
    props: {
        message: Object,
        settings: Object,
        chatSettings: Object,
    },
    created(){
        if(this.chatSettings.welcomePopupEnabled){
            setTimeout(() => this.readyForPopup=true, this.chatSettings.welcomePopupAfterSeconds*1000);
        }
    }
}
</script>

<style lang="scss" scoped>
#welcome-message{
    position: fixed;
    max-width: 50%;
    right: 78pt;
    bottom: 16pt;
    transform-origin: right;
    cursor: pointer;
    background: $bot-response-background;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 5px;
    transition: right 0.5s, transform 0.5s, visibility 0s 0s;
    z-index: 2147483646;
}

#welcome-message::after{
	border-left-color:  $bot-response-background;
}



#welcome-message.hidden{
    right: 28pt;
    transform: scaleX(0.01) scaleY(0.4);
    visibility: hidden;
    transition: right 0.5s, transform 0.5s, visibility 0s 0.49s;

}

#welcome-message.pop-in-enter{
    right: 28pt;
    transform-origin: right;
    transform: scaleX(0.01) scaleY(0.4);    
    visibility: hidden;
}
</style>