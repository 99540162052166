import translations from './i18nMessages.js'
import { ref, provide, inject } from "@vue/composition-api";

const createI18n = config => ({
  language: ref(config.language),
  defaultLanguage: ref(config.defaultLanguage),
  messages: translations(),
  $t(key) {
    if(this.messages[this.language.value][key]) return this.messages[this.language.value][key];
    if(this.messages[this.defaultLanguage.value][key])return this.messages[this.defaultLanguage.value][key];

    return 'Not translated phrase'
  },
  setLanguage(language){
    this.language.value = language;
  },
  setDefaultLanguage(language){
    this.defaultLanguage.value = language;
  }
});

const i18nSymbol = Symbol();

export function provideI18n(i18nConfig) {
  const i18n = createI18n(i18nConfig);
  provide(i18nSymbol, i18n);
}

export function useI18n() {
  const i18n = inject(i18nSymbol);
  if (!i18n) throw new Error("i18n has not been initialized!");

  return i18n;
}